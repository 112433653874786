import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useMeta, useContentfulSection } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"
import { ShipCompareTable } from "src/components/tables"

import { Layout, Seo } from "components/common"

import AppCompareTemplate from "src/templates/AppCompareTemplate.js"

const ShipComparePage = () => {
  const { ship, app_doc, page } = useStaticQuery(query)

  const meta = useMeta(page)

  const { ship_compare_hero } = useContentfulSection(page.sections)
  const slug = "ship"

  return (
    <StyledComparePage>
      <Layout>
        <Seo {...meta} />

        <MobileNav slug={slug} />
        <PrimaryNav slug={slug} />
        <div className="nav-background">
          <AppStickyNav
            disabled={app_doc.disabled}
            slug={slug}
            salesforceAppexchangeUrl={app_doc.salesforceAppexchangeUrl}
          />
        </div>
        <AppCompareTemplate
          section={ship_compare_hero}
          app={app_doc}
          slug={slug}
          table={() => (
            <ShipCompareTable
              slug={slug}
              table={ship}
              switcherTitle="compare Ship by opero to:"
            />
          )}
        />
      </Layout>
    </StyledComparePage>
  )
}

const StyledComparePage = styled.div`
  .nav-background {
    --section-background-color: none;
  }
`

export const query = graphql`
  {
    ship: allGoogleSpreadsheetShip {
      edges {
        node {
          id
          # num
          feature
          opero
          zenKraft
        }
      }
    }
    page: contentfulPage(pageId: { eq: "ship_compare" }) {
      ...Page
    }
    app_doc: contentfulApp(slug: { eq: "ship" }) {
      ...App
    }
  }
`

export default ShipComparePage
